/* eslint-disable indent */

import styled, { css, StyledComponent } from '@xstyled/styled-components'
import { th, variant, breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'

export const Heading: StyledComponent<
  'h1',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.h1`
  font-family: ${th('fonts.title')};
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;

  ${variant({
    prop: 'variant',
    variants: {
      intro: css`
        font-size: ${proportion(144, 'handheld')};
        line-height: ${proportion(128, 'handheld')};
        margin-bottom: 1.4rem;

        ${breakpoints({
          md: css`
            font-size: ${proportion(170, 'desktop')};
            line-height: ${proportion(170, 'desktop')};
            letter-spacing: 0.1rem;
            margin-bottom: 32px;
          `,
          xl: css`
            font-size: 180px;
            line-height: 180px;
          `,
        })}
      `,

      'page-title': css`
        font-size: ${proportion(96, 'handheld')};
        line-height: ${proportion(124, 'handheld')};

        ${breakpoints({
          md: css`
            font-size: ${proportion(110, 'desktop')};
            line-height: ${proportion(120, 'desktop')};
            margin-bottom: ${proportion(34, 'desktop')};
          `,
          xl: css`
            font-size: 110px;
            line-height: 120px;

            letter-spacing: 1px;
            margin-bottom: 34px;
          `,
        })}
      `,

      headline: css`
        font-family: ${th('fonts.primary')};
        font-weight: bold;
        font-size: ${proportion(56, 'handheld')};
        line-height: ${proportion(64, 'handheld')};

        ${breakpoints({
          md: css`
            font-size: ${proportion(58, 'desktop')};
            line-height: ${proportion(58, 'desktop')};
            margin-bottom: ${proportion(15, 'desktop')};
          `,
          xl: css`
            font-size: 29px;
            line-height: 29px;
            margin-bottom: 15px;
          `,
        })}
      `,

      small: css`
        ${breakpoints({
          md: css`
            font-size: ${proportion(40, 'desktop')};
            line-height: ${proportion(40, 'desktop')};
            margin-bottom: ${proportion(30, 'desktop')};
          `,
          xl: css`
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 30px;
          `,
        })}
      `,
    },
  })}
`

export const H = Heading
