import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'
import PropTypes from 'prop-types'
import { responsiveValue } from '@/modules/utils/responsiveValue'
import { Container, Title, Paragraph } from './styled'
import { useContentInProp } from '@/hooks/useContentInProp'

interface Props {
  textAnimationType?: 'mask' | 'clip'
  paragraphText: string
  titleText: string
  useTextAnimation?: boolean
}

const TextIntro: React.FC<Props> = ({ titleText, paragraphText }) => {
  const inProp = useContentInProp()
  const tweenRef = useRef(null)
  const stRef = useRef(null)
  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const paragraphRef = useRef(null)

  useEffect(() => {
    if (stRef.current) {
      stRef.current.revert()
    }

    if (tweenRef.current) {
      tweenRef.current.kill()
    }

    if (!inProp) {
      return
    }

    tweenRef.current = gsap.delayedCall(0, () => {
      if (containerRef.current) {
        containerRef.current.style.opacity = 1
      }

      if (titleRef.current) {
        const tl = gsap.timeline({})
        stRef.current = new SplitText(titleRef.current, {
          type: 'words',
          wordsClass: 'word',
        })

        const { words } = stRef.current

        words.forEach((word, index) => {
          const subTl = gsap.timeline({})
          const { chars } = new SplitText(word, {
            type: 'chars',
          })

          subTl.fromTo(
            word,
            0.5,
            {
              y: responsiveValue({ default: 72, md: 156 }),
            },
            {
              ease: 'sine.out',
              y: 0,
            },
            0.25,
          )

          subTl.fromTo(
            chars,
            0.5,
            {
              y: responsiveValue({ default: 84, md: 180 }),
            },
            {
              ease: 'sine.out',
              stagger: 0.005,
              y: 0,
            },
            0.75,
          )

          tl.add(subTl, 0.1 * index)
        })

        tl.fromTo(
          paragraphRef.current,
          0.35,
          {
            opacity: 0,
            y: 20,
          },
          {
            ease: 'sine.out',
            opacity: 1,
            y: 0,
          },
          1.3,
        )
      }
    })
  }, [inProp])

  const paragraphTextBreaks = paragraphText.replace(/(?:\r\n|\r|\n)/g, '<br/>')

  return (
    <Container ref={containerRef}>
      <Title variant="intro" ref={titleRef}>
        {titleText}
      </Title>
      <Paragraph
        ref={paragraphRef}
        dangerouslySetInnerHTML={{ __html: paragraphTextBreaks }}
      />
    </Container>
  )
}

TextIntro.propTypes = {
  paragraphText: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
}

export default TextIntro
