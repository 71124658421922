import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { Heading } from '../Heading'

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: 100%;
  text-align: center;

  ${breakpoints({
    md: css`
      text-align: left;
    `,
  })}
`

export const Title = styled(Heading)`
  text-align: center;

  .word {
    clip-path: inset(0 0 0 0);
    padding-top: 0.4rem;
  }
`

export const Paragraph = styled.p`
  font-size: md;
  text-align: center;
`
